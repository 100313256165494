<template>
  <div>
    <nav>
      <div class="nav-menu">
        <a href="/">
          <img
            src="@/assets/images/logo.png"
            alt="Logo Meo Empresas Horizontal"
            class="logo-horizon"
          />
        </a>
        <div class="nav-links nav-links-icon">
          <a href="#" @click="change()">
            <md-icon class="icon-menu">person</md-icon>
          </a>
        </div>
        <div class="nav-links toggle">
          <a class="nav-links-icon icon" href="#" @click="change()">
            <md-icon class="icon-menu">person</md-icon>
          </a>
        </div>
      </div>
    </nav>
    <div class="dropdown-content-mobile" v-if="profile">
      <div class="item-menu-mobile">
        <a href="/" @click="profile = false" class="logout">Entrar<md-icon>keyboard_arrow_right</md-icon></a>>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultHeader',
  data () {
    return {
      profile: false
    }
  },
  methods: {
    change () {
      this.profile = this.profile !== true
    }
  }
}
</script>

<style lang="scss">
.logo-horizon {
  float: left;
  margin: 0 15px 0;
  width: 100px;
}
.icon-menu {
  padding: 0px;
}
.dropdown-content {
  position: absolute;
  right: 0;
  background-color: #000000;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 999;
}
.quick-links {
  margin: 0 0 18px;
  position: relative;
  // padding: 3px 0 3px 50px;
  font-size: 12px;
  text-transform: none;
  text-decoration: none;
  display: block;
  text-align: left;
}
.logout {
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none !important;
  color: #fff !important;
  font-size: 10px;
  &:hover,
  &:focus {
    color: #0084D6 !important;
  }
}
.sub-menu {
  text-align: left;
  text-decoration: none !important;
  color: #fff !important;
  font-size: 10px;
  &:hover,
  &:focus {
    color: #0084D6 !important;
  }
}
.dropdown-content-mobile {
  position: absolute;
  right: 0;
  width: 100vw;
  background-color: #000000;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 999;
}
.item-menu-mobile {
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 10px;
  &:hover,
  &:focus {
    color: #0084D6 !important;
  }
  padding: 10px;
}
.item-menu-mobile-text {
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 10px;
  &:hover,
  &:focus {
    color: #0084D6!important;
  }
}
</style>
